import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // useLocation,
  // useNavigate,
  // Navigate,
  Outlet,
} from "react-router-dom";
import "./assets/globalstyle/globalstyle.css";

import { ToastContainer as DefaultToastContainer } from "react-toastify";

// Import Contexts
import { theme, ThemeProvider } from "./context/ThemeProvider";
// import SuspenseFallback from "./components/SuspenseFallback";
// import ErrorBoundary from "./components/ErrorBoundary";
import NoPage from "./components/UnknownPage";

// Other assets
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

// Import Pages
import Dashboard from "./pages/Dashboard";
// import { useSelector } from "react-redux";
import {
  LoggedInRoute,
  ProtectedRoutes,
} from "./utils/GlobalUtils/ProtectedRoutes";
import ChangeBankAccount from "./layouts/Settings/ChangeBankAccount";

const Onboarding = lazy(() =>
  import("./pages/authPages/onboarding/Onboarding")
);

const SetPassword = lazy(() =>
  import("./pages/authPages/onboarding/SetPassword")
);

const FinalOnboarding = lazy(() =>
  import("./pages/authPages/onboarding/FinalOnboarding")
);

const ForgotPassword = lazy(() =>
  import("./pages/authPages/forgotPassword/ForgotPassword")
);

const OtpPassword = lazy(() =>
  import("./pages/authPages/otpPassword/OtpPassword")
);

const ResetPassword = lazy(() =>
  import("./pages/authPages/resetPassword/ResetPassword")
);

const ResetPasswordSuccess = lazy(() =>
  import("./pages/authPages/resetPasswordSuccess/ResetPasswordSuccess")
);

const AccountSetup = lazy(() =>
  import("./components/Account-setup/AccountSetup")
);

const DashboardIndexSection = lazy(() => import("./layouts/dashboard/Index"));
const Performance = lazy(() => import("./layouts/dashboard/performance"));

const Showcase = lazy(() => import("./layouts/Showcase"));

const ResellerPerformanceView = lazy(() =>
  import("./layouts/Performance/ResellerPerformanceView")
);
const AgentPerformanceView = lazy(() =>
  import("./layouts/Performance/AgentPerformanceView")
);
const ProductsForAgentPerformance = lazy(() =>
  import("./layouts/Performance/ProductsForAgentPerformance")
);
const AgentPerformanceViewById = lazy(() =>
  import("./layouts/Performance/AgentPerformanceViewById")
);

const Users = lazy(() => import("./layouts/UserManagement/Users"));

const Customers = lazy(() => import("./layouts/UserManagement/Customers"));

const Merchants = lazy(() => import("./layouts/UserManagement/Merchants"));

const CustomersWallets = lazy(() =>
  import("./layouts/UserManagement/CustomersWallets")
);

const MakerChecker = lazy(() =>
  import("./layouts/UserManagement/MakerChecker")
);

// const Roles = lazy(() => import("./layouts/RoleManagement/Roles"));
const Commission = lazy(() => import("./layouts/Commission/Commission"));

// const Roles = lazy(() => import("./layouts/RoleManagement/Roles"));

const WIPAYReports = lazy(() => import("./layouts/WIPAY/WIPAY"));

const CustomerListing = lazy(() =>
  import("./layouts/Report/AgentListing/CustomerListing")
);
const SingleCustomerListing = lazy(() =>
  import("./layouts/Report/AgentListing/SingleCustomerListing")
);
const TransactionListing = lazy(() =>
  import("./layouts/Report/TransactionListing")
);
const MainWallet = lazy(() => import("./layouts/Report/MainWallet"));
const EcobankReport = lazy(() => import("./layouts/Report/EcoBankReport"));
const CommissionWallet = lazy(() =>
  import("./layouts/Report/CommissionWallet")
);
const AirtimeReport = lazy(() => import("./layouts/Report/AirtimeReport"));
const DataReport = lazy(() => import("./layouts/Report/DataReport"));
const BillsReport = lazy(() => import("./layouts/Report/BillsReport"));
const ElectricityReport = lazy(() =>
  import("./layouts/Report/ElectricityReport")
);
const CableTVReport = lazy(() => import("./layouts/Report/CableTVReport"));
const CashOutReport = lazy(() => import("./layouts/Report/CashoutReport"));
const InwardTransferReport = lazy(() =>
  import("./layouts/Report/InwardReport")
);
const OutwardTransferReport = lazy(() =>
  import("./layouts/Report/OutwardReport")
);

const TransactionSummaryModule = lazy(() =>
  import("./layouts/Report/TransactionSummaryModule")
);
// const ViewSubclusterLots = lazy(() =>
//   import("./layouts/Report/ViewSubCluster")
// );

const DebitCustomerModule = lazy(() =>
  import("./layouts/DebitCustomerModule/DebitCustomerModule")
);

// const InputTracking = lazy(() => import("./layouts/InputTracking/Input"));

const UploadFileInvoicing = lazy(() =>
  import("./layouts/Invoicing/uploadFile/UploadFileInvoice")
);
const GenerateInvoice = lazy(() =>
  import("./layouts/Invoicing/invoiceData/RawInvoiceData")
);

const GeneratedInvoice = lazy(() =>
  import("./layouts/Invoicing/invoiceData/GeneratedInvoice")
);

const ValidationError = lazy(() =>
  import("./layouts/Invoicing/validationError/ValidationError")
);

const ValidationErrorConsignmentDetails = lazy(() =>
  import("./layouts/Invoicing/validationError/ConsignmentDetails")
);

const ValidationErrorWholesaleDetails = lazy(() =>
  import("./layouts/Invoicing/validationError/WholesaleDetails")
);

const GenerateConsignmentDetails = lazy(() =>
  import("./layouts/Invoicing/invoiceData/ConsignmentDetails")
);

const GenerateWholesaleDetails = lazy(() =>
  import("./layouts/Invoicing/invoiceData/WholesaleDetails")
);

const SapCode = lazy(() => import("./layouts/Invoicing/sapCode/SapCode"));

const InvoiceReport = lazy(() => import("./layouts/Invoicing/report/Report"));

const InvoiceDebit = lazy(() => import("./layouts/Invoicing/debit/DebitView"));

const Products = lazy(() => import("./layouts/Products/Products"));

const PendingFund = lazy(() => import("./layouts/FundingModule/PendingFund"));

const FundingModule = lazy(() =>
  import("./layouts/FundingModule/FundingModule")
);

const TransactionPin = lazy(() => import("./layouts/Settings/TransactionPin"));

const PaymentTransactions = lazy(() =>
  import("./layouts/Settings/Transactions")
);
const PaymentDisbursments = lazy(() =>
  import("./layouts/Settings/ChangePassword")
);
// const ChangeBankAccount = lazy(() =>
//   import("./layouts/Settings/ChangeBankAccount")
// );

const CardNetwork = lazy(() => import("./layouts/CardNetwork/CardNetwork"));

const Login = lazy(() => import("./pages/authPages/login/Login"));

const Holding = lazy(() => import("./pages/authPages/login/HoldingPage"));

// const Test = lazy(() => import("./pages/Test"));

// create a default container so we can override the styles
const ToastContainer = (props) => (
  <DefaultToastContainer style={{ zIndex: "1900" }} {...props} />
);

function App() {
  return (
    <Suspense fallback={"Loading..."}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {/* <Route exact path='/' element={<Login />} /> */}
            <Route path="/confirm-registration" element={<Onboarding />} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route
              path="/successful-onboarding"
              element={<FinalOnboarding />}
            />
            {/* <Route path='/login' element={(!(auth?.access_token) ? <Login /> : <Navigate to='/account-setup' false/>)} /> */}
            <Route element={<LoggedInRoute />}>
              <Route path="/" element={<Login />} />
            </Route>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/otp-password" element={<OtpPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/reset-password-success"
              element={<ResetPasswordSuccess />}
            />

            {/* DASHBOARD */}
            <Route element={<ProtectedRoutes />}>
              <Route path={"user"} element={<Holding />} />
              <Route path="account-setup" element={<AccountSetup />} />
              <Route path="/dashboard" element={<Dashboard />}>
                {/* <Route index element={""} /> */}
                <Route index element={<DashboardIndexSection />} />
                <Route path={"showcase"} element={<Showcase />} />
                <Route path="settings" element={<Outlet />}>
                  <Route index element={""} />
                  <Route path="change-pin" element={<PaymentTransactions />} />
                  <Route
                    path="change-password"
                    element={<PaymentDisbursments />}
                  />
                  <Route
                    path="set-transaction-pin"
                    element={<TransactionPin />}
                  />
                  <Route
                    path="change-bank-account"
                    element={<ChangeBankAccount />}
                  />
                </Route>

                <Route path="user-management" element={<Outlet />}>
                  <Route index element={""} />
                  <Route path="users" element={<Users />} />
                  <Route path="marker-checker" element={<MakerChecker />} />
                  <Route path="customers" element={<Outlet />}>
                    <Route index element={<Customers />} />
                    <Route path=":id/wallets" element={<CustomersWallets />} />
                  </Route>
                  <Route path="merchants" element={<Outlet />}>
                    <Route index element={<Merchants />} />
                    <Route path=":id/wallets" element={<CustomersWallets />} />
                  </Route>
                </Route>

                {/* <Route path="roles" element={<Roles />} /> */}

                <Route path="report" element={<Outlet />}>
                  <Route index element={<DashboardIndexSection />} />
                  {/* <Route index element={""} /> */}
                  <Route path="agent-listing" element={<Outlet />}>
                    <Route index element={<CustomerListing />} />
                    <Route path=":id" element={<SingleCustomerListing />} />
                  </Route>
                  <Route
                    path="transaction-listing"
                    element={<TransactionListing />}
                  />
                  <Route path="main-wallet" element={<MainWallet />} />

                  <Route
                    path="commission-wallet"
                    element={<CommissionWallet />}
                  />
                  <Route
                    path="transaction-summary"
                    element={<TransactionSummaryModule />}
                  />
                  <Route path="airtime-report" element={<AirtimeReport />} />
                  <Route path="data-report" element={<DataReport />} />
                  <Route path="bills-report" element={<BillsReport />} />
                  <Route
                    path="electricity-report"
                    element={<ElectricityReport />}
                  />
                  <Route path="cable-report" element={<CableTVReport />} />
                  <Route path="cashout-report" element={<CashOutReport />} />
                  <Route
                    path="inward-transfers-report"
                    element={<InwardTransferReport />}
                  />
                  <Route
                    path="outward-transfers-report"
                    element={<OutwardTransferReport />}
                  />
                </Route>
                <Route path="other-reports" element={<Outlet />}>
                  <Route index element={<DashboardIndexSection />} />
                  <Route path="wipay" element={<WIPAYReports />} />
                  <Route path="ecobank-report" element={<EcobankReport />} />
                </Route>
                {/* UploadFileInvoicing */}
                <Route path="invoicing" element={<Outlet />}>
                  <Route index element={<DashboardIndexSection />} />
                  <Route path="upload-file" element={<UploadFileInvoicing />} />
                  <Route
                    path="generate-invoice"
                    element={<GenerateInvoice />}
                  />
                  <Route
                    path="generated-invoice"
                    element={<GeneratedInvoice />}
                  />
                  <Route
                    path="view-validation-error"
                    element={<ValidationError />}
                  />
                  <Route path="erp-code" element={<SapCode />} />
                  <Route path="invoice-report" element={<InvoiceReport />} />

                  <Route path="invoice-debit" element={<InvoiceDebit />} />
                  <Route
                    path="view-validation-error/consignment/:id"
                    element={<ValidationErrorConsignmentDetails />}
                  />
                  <Route
                    path="view-validation-error/wholesale/:id"
                    element={<ValidationErrorWholesaleDetails />}
                  />

                  <Route
                    path="generate-invoice/consignment/:id"
                    element={<GenerateConsignmentDetails />}
                  />
                  <Route
                    path="generate-invoice/wholesale/:id"
                    element={<GenerateWholesaleDetails />}
                  />
                </Route>
                <Route
                  path={"performance-management"}
                  element={<Performance />}
                />
                <Route
                  path="performance-management/reseller"
                  element={<ResellerPerformanceView />}
                />
                <Route
                  path="performance-management/agent"
                  element={<AgentPerformanceView />}
                />
                <Route
                  path="performance-management/agent/products/:id"
                  element={<ProductsForAgentPerformance />}
                />
                <Route
                  path="performance-management/agent/:id"
                  element={<AgentPerformanceViewById />}
                />
                <Route
                  path="debit-customer-module"
                  element={<DebitCustomerModule />}
                />
                <Route path="card-network" element={<CardNetwork />} />
                <Route path="products" element={<Products />} />
                <Route path="commissions" element={<Commission />} />
                <Route path="funding" element={<Outlet />}>
                  <Route index element={""} />
                  <Route path="customer-wallet" element={<FundingModule />} />
                  <Route path="pending-fund" element={<PendingFund />} />
                </Route>
                <Route path="*" element={<NoPage />} />
              </Route>

              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
